
// start text at 0, end "off screen" to the left
@keyframes ticker {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-100%);
  }
}
// start wrapper "off screen" to the right, end at 0
@keyframes ticker-wrapper {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}

.anim-wrapper {
  width: 100%;
  height: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker-wrapper;
}
