@use '@tagboard/tgb-component-lib/dist/style.css';

@import "./lib/animate";
@import "./shared/animations";
@import "./shared/defaults";

/* TODO: Move this ish */
@import "../js/components/layouts/graphics-wrapper/graphics/sass/main.scss";

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  overflow: hidden;
}

body {
  @include no-select;
}

#app,
#tagboard-live {
  @include fullscreen;
}

// touchscreen controls
.touchscreen-control {
  position: fixed;
  height: 100%;
  width: 15%;
  top: 0;
  opacity: 0.5;
  z-index: 9999;
  cursor: pointer;

  &.previous {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.waterfall .touchscreen-control,
.grid .touchscreen-control,
.panel .touchscreen-control {
  display: none;
}

#app > div > .grid .fullscreen-btn,
#app > div > .waterfall .fullscreen-btn {
  display: none;
}

// CONNECT SCREEN
.connect-screen {
  @include fullscreen;
  padding-top: 100px;
  background: $prod-dark-blue;

  // display: none; // temp

  .tagboard-logo {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 80px;
    background: $tagboard-logo-white 50% 50% no-repeat;
    background-size: contain;
  }

  .connect-display {
    position: relative;
    width: 100%;
    height: 100%;
    @include text-select;
  }

  .code {
    position: relative;
    padding: 20px;
    @include vertical-align;
    top: calc(50% - 100px);

    h2 {
      display: inline-block;
      font-size: 6vmin;
      padding: .5em;
      margin-top: .5em;
      letter-spacing: .15em;
      background: $prod-medium-blue;
      border-radius: 3px;
      box-shadow: 0 0 4px rgba($black, .5), inset 0 1px 2px rgba($black, .5);
    }

    h4 {
      font-size: 3vmin;
    }
  }
}

// .no-posts {
//   position: absolute;
//   @include center-align;
//   color: #fff;
// }

@import "live";
