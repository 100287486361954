
.qr-wrapper {
  // confine the QR code to a square
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.qr-code {
  // !important to override nested styles that still affect v1 QR code
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  &::after {
    content: 'privacy.tgb.io';
    font-size: clamp(13px, 1vw, .4em);
    margin: 0;
    display: block;
    text-align: center;
    color: var(--dot-color);
    line-height: 1 !important;
    padding: 5px 0 0 !important;
  }
}

// mirror ::after above for things that will affect the size
.qr-privacy {
  font-size: clamp(13px, 1vw, .4em);
  margin: 0;
  display: block;
  line-height: 1;
  padding: 5px 0 0;
  // hide it
  visibility: hidden;
  opacity: 0;
}
