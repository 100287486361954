
.qr-code{
  &:after{
    font-size: clamp(12px, .8em, 4rem);
    content: 'privacy.tgb.io';
    margin: auto;
    display: block;
    text-align: center;
    color: var(--dot-color);
    background: var(--background-color);
    line-height: 1;
    margin-top: -4px;
    padding: 5px 0;
  }
}
