// Custom animations

// flyInLeft

@-webkit-keyframes flyInLeft {
  from {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes flyInLeft {
  from {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.flyInLeft {
  -webkit-animation-name: flyInLeft;
  animation-name: flyInLeft;
}

// flyInRight

@-webkit-keyframes flyInRight {
  from {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes flyInRight {
  from {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.flyInRight {
  -webkit-animation-name: flyInRight;
  animation-name: flyInRight;
}

// flyOutLeft

@-webkit-keyframes flyOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
  }
}

@keyframes flyOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(-200%, 0, 0);
    transform: translate3d(-200%, 0, 0);
  }
}

.flyOutLeft {
  -webkit-animation-name: flyOutLeft;
  animation-name: flyOutLeft;
}

// flyOutRight

@-webkit-keyframes flyOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0);
  }
}

@keyframes flyOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(200%, 0, 0);
    transform: translate3d(200%, 0, 0);
  }
}

.flyOutRight {
  -webkit-animation-name: flyOutRight;
  animation-name: flyOutRight;
}

// swingInLeft

@-webkit-keyframes swingInLeft {
  from {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-20%, 0, 200px) rotateY(-45deg);
    transform: translate3d(-20%, 0, 200px) rotateY(-45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes swingInLeft {
  from {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-20%, 0, 200px) rotateY(-25deg);
    transform: translate3d(-20%, 0, 200px) rotateY(-25deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.swingInLeft {
  -webkit-animation-name: swingInLeft;
  animation-name: swingInLeft;
}

// swingInRight

@-webkit-keyframes swingInRight {
  from {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(20%, 0, -200px) rotateY(25deg);
    transform: translate3d(20%, 0, -200px) rotateY(25deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes swingInRight {
  from {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(20%, 0, -200px) rotateY(25deg);
    transform: translate3d(20%, 0, -200px) rotateY(25deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.swingInRight {
  -webkit-animation-name: swingInRight;
  animation-name: swingInRight;
}

// swingOutLeft

@-webkit-keyframes swingOutLeft {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-20%, 0, 200px) rotateY(-25deg);
    transform: translate3d(-20%, 0, 200px) rotateY(-25deg);
    opacity: 0;
  }
}

@keyframes swingOutLeft {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-20%, 0, 200px) rotateY(-25deg);
    transform: translate3d(-20%, 0, 200px) rotateY(-25deg);
    opacity: 0;
  }
}

.swingOutLeft {
  -webkit-animation-name: swingOutLeft;
  animation-name: swingOutLeft;
}

// swingOutRight

@-webkit-keyframes swingOutRight {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(20%, 0, -200px) rotateY(25deg);
    transform: translate3d(20%, 0, -200px) rotateY(25deg);
    opacity: 0;
  }
}

@keyframes swingOutRight {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(20%, 0, -200px) rotateY(25deg);
    transform: translate3d(20%, 0, -200px) rotateY(25deg);
    opacity: 0;
  }
}

.swingOutRight {
  -webkit-animation-name: swingOutRight;
  animation-name: swingOutRight;
}

// slideInLeft3d

@-webkit-keyframes slideInLeft3d {
  from {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-175%, 0, 400px);
    transform: translate3d(-175%, 0, 400px);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes slideInLeft3d {
  from {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-175%, 0, 400px);
    transform: translate3d(-175%, 0, 400px);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.slideInLeft3d {
  -webkit-animation-name: slideInLeft3d;
  animation-name: slideInLeft3d;
}

// slideInRight3d

@-webkit-keyframes slideInRight3d {
  from {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(175%, 0, -400px);
    transform: translate3d(175%, 0, -400px);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes slideInRight3d {
  from {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(175%, 0, -400px);
    transform: translate3d(175%, 0, -400px);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.slideInRight3d {
  -webkit-animation-name: slideInRight3d;
  animation-name: slideInRight3d;
}

// slideOutLeft3d

@-webkit-keyframes slideOutLeft3d {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-175%, 0, 400px);
    transform: translate3d(-175%, 0, 400px);
    opacity: 0;
  }
}

@keyframes slideOutLeft3d {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
    -webkit-transform: translate3d(-175%, 0, 400px);
    transform: translate3d(-175%, 0, 400px);
    opacity: 0;
  }
}

.slideOutLeft3d {
  -webkit-animation-name: slideOutLeft3d;
  animation-name: slideOutLeft3d;
}

// slideOutRight3d

@-webkit-keyframes slideOutRight3d {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(175%, 0, -400px);
    transform: translate3d(175%, 0, -400px);
    opacity: 0;
  }
}

@keyframes slideOutRight3d {
  from {
    -webkit-transform-origin: none;
    transform-origin: none;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: 0 -50% 0;
    transform-origin: 0 -50% 0;
    -webkit-transform: translate3d(175%, 0, -400px);
    transform: translate3d(175%, 0, -400px);
    opacity: 0;
  }
}

.slideOutRight3d {
  -webkit-animation-name: slideOutRight3d;
  animation-name: slideOutRight3d;
}

// Setup perspective for Swing animation
.posts.Swing {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  perspective: 400px;
}

.animated.cutIn {
  -webkit-animation: cutIn 10ms;
  animation: cutIn 10ms;
}

.animated.cutOut {
  -webkit-animation: cutOut 10ms;
  animation: cutOut 10ms;
}

@-webkit-keyframes cutIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes cutIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes cutOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes cutOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// scaleUpIn

@-webkit-keyframes scaleUpIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.2, .2, .2);
    transform: scale3d(.2, .2, .2);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes scaleUpIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(.2, .2, .2);
    transform: scale3d(.2, .2, .2);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.scaleUpIn {
  -webkit-animation-name: scaleUpIn;
  animation-name: scaleUpIn;
}

// scaleUpOut

@-webkit-keyframes scaleUpOut {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

@keyframes scaleUpOut {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

.scaleUpOut {
  -webkit-animation-name: scaleUpOut;
  animation-name: scaleUpOut;
}

// scaleDownIn

@-webkit-keyframes scaleDownIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes scaleDownIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.scaleDownIn {
  -webkit-animation-name: scaleDownIn;
  animation-name: scaleDownIn;
}

// scaleDownOut

@-webkit-keyframes scaleDownOut {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.2, .2, .2);
    transform: scale3d(.2, .2, .2);
  }
}

@keyframes scaleDownOut {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(.2, .2, .2);
    transform: scale3d(.2, .2, .2);
  }
}

.scaleDownOut {
  -webkit-animation-name: scaleDownOut;
  animation-name: scaleDownOut;
}
