// .fullscreen-btn {
//   position: absolute;
//   bottom: 4vmin;
//   right: 4vmin;
//   width: auto;
//   height: auto;
//   font-size: 3vmin;
//   line-height: 1;
//   padding: .75em;
//   border-radius: .25em;
//   background-color: rgba($black, .75);
//   box-shadow: 0 0 .25em rgba($white, .25);
//   cursor: pointer;
//   display: none;
//   z-index: 9999;

//   .icon {
//     line-height: 1;
//   }
// }

// #tagboard-live:hover .fullscreen-btn {
//   display: block;
// }

// #tagboard-live.isFullScreen:hover .fullscreen-btn {
//   display: none;
// }

// #tagboard-live.isFullScreen {
//   cursor: none !important;
// }

.display,
.theme-wrapper,
.fullscreen-wrapper,
.display-wrapper,
.multiple-graphics-wrapper,
.backdrop {
  @include fullscreen;
  overflow: hidden;
}

.safe-area {
  .display-wrapper {
    inset: 0;
    margin: auto;

    &.empty-header {
      .posts {
        inset: 0;
        margin: auto;
      }
    }
  }
}

.carousel {
  .safe-area.sidebar {
    .display-wrapper {
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .safe-area.lowerthird {
    .display-wrapper {
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.lowerthirdscroll-v1_5, .lowerthird-v1_5 {
  .safe-area {
    .backdrop {
      top: auto;
      bottom: 0;
    }
  }
}

.graphics-wrapper {
  transform-origin: top left;
}

.display {
  @include base-sans-serif-font;
  z-index: 999;
  cursor: none;

  &__videoBackground {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.fullscreen-wrapper {
  background-color: lighten($prod-dark-blue, 5%);

  &.interactive {
    cursor: auto;

    .post {
      cursor: pointer;
    }
  }
}

.backdrop {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, .5);
}

.darkened-background .background-overlay {
  display: block;
}

.posts {
  position: absolute;
}

.post {
  line-height: 1.3;

  a {
    pointer-events: none;
  }
}

.post-panel {
  position: absolute;
  width: 100%;
  height: 100%;
}

.post-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.origin-flag {
  @include origin-flag-setup;
}

.play-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: .5em;
  width: 3em;
  height: 2em;
  border-radius: 2em;
  color: $white;
  text-align: center;
  background-color: rgba($black, .5);
  box-shadow: 0 0 0 2px rgba($white, 0.5), 0 2px 4px rgba($black, 0.25);
  opacity: .8;
  @include opacity-transition;

  .icon {
    font-size: 1.5em;
    line-height: 1.35;
  }
}

.blurred-media {
  position: absolute;
  top: -10vmax;
  left: -10vmax;
  width: calc(100% + 20vmax);
  height: calc(100% + 20vmax);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-filter: blur(5vmax);
  -moz-filter: blur(5vmax);
  -o-filter: blur(5vmax);
  -ms-filter: blur(5vmax);
  filter: blur(5vmax);
}

.twitter-media-url {
  display: none;
}

.twitter .verified .author,
.tiktok .verified .author {
  padding-right: 1em;
  background-image: $twitter-verified-icon;
  background-position: 100% 50%;
  background-size: .8em .8em;
  background-repeat: no-repeat;
}

.twitter .verified.org .author {
  background-image: $twitter-verified-org;
}

.twitter .verified.gov .author{
  background-image: $twitter-verified-gov;
}

.tiktok .verified .author {
  background-image: $tiktok-verified-icon;
}

.youtube {
  .media-module {
    display: flex;
    align-items: center;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

.heymarket{
  .meta-author {
    display: none;
  }

  .message {
    padding-right: 1.25em;
  }
}

.twitch.chat{
  color: black;
}

.facebook-vid-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-out {
  -webkit-animation: fadeOut .75s ease forwards;
  animation: fadeOut .75s ease forwards;
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;


  &.smart-panel {
    position: absolute;
  }
}
