.element {
  position: absolute;
  perspective: 1920px;

  &.slideInUp,
  &.slideInDown,
  &.slideInLeft,
  &.slideInRight,
  &.slideOutUp,
  &.slideOutDown,
  &.slideOutLeft,
  &.slideOutRight {
    overflow: hidden;

    &.group, &.group-wrap {
      /* I'm scared to remove the above overflow styling, so just don't hide overflow for groups */
      overflow: visible;
    }
  }

  .animated-element {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    outline: 0px solid transparent;
    animation-fill-mode: both;

    &.enter {
      opacity: 0;
      backface-visibility: hidden;
    }
  }
}

.element.text {
  .text-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .message {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    &.vertical-top {
      .message {
        justify-content: flex-start;
      }
    }

    &.vertical-middle {
      .message {
        justify-content: center;
      }
    }

    &.vertical-bottom {
      .message {
        justify-content: flex-end;
      }
    }

    p {
      margin: 0;
    }

    ul,
    ol {
      margin: 0;
    }

    &.ticker {
      .message {
        width: auto;
        white-space: nowrap;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: ticker;

        .ticker-text {
          margin-right: 1rem; // space for never-ending ticker
        }

        p {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  }
}

.element.video {
  .video-wrap {
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .video-poster {
      width: 100%;
      height: 100%;
    }
  }
}

.element.image {
  .image-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    line-height: 0;

    img,
    video {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .fit img,
    .fit video {
      object-fit: contain;
    }

    .fill img,
    .fill video {
      object-fit: cover;
    }

    .stretch img,
    .stretch video {
      object-fit: fill;
    }

    .mask {
      overflow: hidden;

      img,
      video {
        object-fit: cover;
      }

      &.top-left img, video {
        object-position: top left;
      }
      &.top-center img, video {
        object-position: top center;
      }
      &.top-right img, video {
        object-position: top right;
      }
      &.center-left img, video {
        object-position: center left;
      }
      &.center-center img, video {
        object-position: center center;
      }
      &.center-right img, video {
        object-position: center right;
      }
      &.bottom-left img, video {
        object-position: bottom left;
      }
      &.bottom-center img, video {
        object-position: bottom center;
      }
      &.bottom-right img, video {
        object-position: bottom right;
      }
    }

    .oval {
      border-radius: 50%;
    }
  }
}

.element.shape {
  .shape-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  &.oval .shape-wrap .background {
    border-radius: 100%;
  }

  &.triangle .shape-wrap .background{
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}

.element.group {
  .group-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
  }
}

.element.marquee {
  .marquee-wrap {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    overflow: hidden;
    display: inline-flex;

    &.align-items {
      &-center {
        align-items: center;

        .tgb-marquee__content-repeater {
          display: inline-flex;
          align-items: center;
        }
      }
      &-start {
        align-items: flex-start;

        .tgb-marquee__content-repeater {
          display: inline-flex;
          align-items: flex-start;
        }
      }
      &-end {
        align-items: flex-end;

        .tgb-marquee__content-repeater {
          display: inline-flex;
          align-items: flex-end;
        }
      }
    }
  }

  .marquee-image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

@keyframes moveRight {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.slideshow {
  .slide-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.progress-indicator {
  z-index: 100;

  .progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation-name: moveRight;
    animation-timing-function: linear;
  }

  .progress-single-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .progress {
      animation-iteration-count: infinite;
    }
  }

  .progress-stories-wrap {
    width: 100%;
    height: 100%;
    display: flex;

    .stories-bar {
      position: relative;
      flex: 1;
      height: 100%;
      margin: 0 0.5em;
      border-radius: 0.75em;
      overflow: hidden;
    }
  }
}

.qrcode-wrap {
  .qr-code {
    height: calc(100% - 12px);
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }

    canvas {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

/// EXTRA ANIMATIONS

.animated.flip {
  animation-name: flipLoop;
}

@keyframes flipLoop {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.popIn {
  animation-name: popIn;
  transform-origin: 50% 50%;
  overflow: visible;
  animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.popOut {
  animation-name: popOut;
  transform-origin: 50% 50%;
  animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
}

@keyframes popOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.5);
  }
}


.flipInOut {
  &.out {
    animation-name: flipIn;
  }

  &.in {
    animation-name: flipOut;
  }
}

@keyframes flipIn {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flipOut {
  0% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(0);
  }
}

.fadeInOut {
  &.out {
    animation-name: fadeIn;
    animation-fill-mode: both;
  }

  &.in {
    animation-name: fadeOut;
    animation-fill-mode: forwards;
  }
}

.rollInOut {
  &.out {
    animation-name: rollIn;
    animation-fill-mode: both;
  }

  &.in {
    animation-name: rollOut;
    animation-fill-mode: forwards;
  }
}

.popInOut {
  &.out {
    animation-name: popIn;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: popOut;
    animation-fill-mode: forwards;
  }
}

.slideInOutRight {
  &.out {
    animation-name: slideInRight;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: slideOutLeft;
    animation-fill-mode: forwards;
  }
}

.slideInOutLeft {
  &.out {
    animation-name: slideInLeft;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: slideOutRight;
    animation-fill-mode: forwards;
  }
}

.slideInOutUp {
  &.out {
    animation-name: slideInUp;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: slideOutDown;
    animation-fill-mode: forwards;
  }
}

.slideInOutDown {
  &.out {
    animation-name: slideInDown;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: slideOutUp;
    animation-fill-mode: forwards;
  }
}

.fadeInOutRight {
  &.out {
    animation-name: fadeInRight;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: fadeOutLeft;
    animation-fill-mode: forwards;
  }
}

.fadeInOutLeft {
  &.out {
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: fadeOutRight;
    animation-fill-mode: forwards;
  }
}

.fadeInOutUp {
  &.out {
    animation-name: fadeInUp;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: fadeOutDown;
    animation-fill-mode: forwards;
  }
}

.fadeInOutDown {
  &.out {
    animation-name: fadeInDown;
    animation-fill-mode: forwards;
  }

  &.in {
    animation-name: fadeOutUp;
    animation-fill-mode: forwards;
  }
}

// social content
.social-ddg {
  // styles needed for transitions to work properly
  top: 0px;
  left: 0px;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  white-space: pre-wrap;
  // end transition styles

  .image-wrap {
    overflow: hidden;
  }

  &.hide-link {
    .link {
      display: none!important;
    }
  }

  &.remove-spaces {
    white-space: normal!important;
  }

  /* Twitch Styling */
  .social-ddg-text--twitch.social-ddg-text--html {
    span:not(:last-child) {
      margin-right: 5px;
    }

    .badges img, .emote img {
      width: 1em;
      margin-right: 5px;
    }

    .username {
      font-weight: bold;

      &::after {
        content: ":";
      }
    }

    .timestamp {
      display: none;
    }

    p.quote-reply{
      font-style: italic;
      margin-bottom: 2vmin;
    }
  }
}
//social content fixes for reddit

.social-ddg--reddit {
  .social-ddg-text--html {
    white-space: auto;
  }

  .social-ddg-text--html * {
    font: inherit;
    line-height: inherit;
    margin: inherit;
    padding: inherit;
  }

  .social-ddg-text--html table{
    all: inherit;
    table-layout:auto;
    border-collapse: collapse;

  }

  .social-ddg-text--html table th,
  .social-ddg-text--html table td {
    padding: .25em;
    border: 3px solid #c1c1c1;
  }

  .social-ddg-text--html hr  {
    border:0;
    border-top:1px solid #555;
    margin:0;
    height:1px;
  }

  .social-ddg-text--html h1 {
    font-size: 2em;
  }
  .social-ddg-text--html h2 {
    font-size: 1.75em;
  }
  .social-ddg-text--html h3 {
    font-size: 1.5em;
  }
  .social-ddg-text--html h4 {
    font-size: 1.25em;
  }
  .social-ddg-text--html h5 {
    font-size: 1.15em;
  }
  .social-ddg-text--html h6 {
    font-weight: bold;
  }

  .social-ddg-text--html strong,
  .social-ddg-text--html b {
    font-weight: bold;
  }

  .social-ddg-text--html em,
  .social-ddg-text--html i {
    font-style: italic;
  }

  .social-ddg-text--html p,
  .social-ddg-text--html div {
    padding-bottom: .5em;
  }

  .social-ddg-text--html ol,
  .social-ddg-text--html ul {
    display: block;
    margin-block-start: 0.25em;
    list-style: inside decimal;
    padding-inline-start: 0.5em;
    padding-top: 0.25em;

    p {
      all: unset;
    }
  }

  .social-ddg-text--html ol {
    list-style-type: decimal;
  }

  .social-ddg-text--html ul {
    list-style-type: disc;
  }

  .social-ddg-text--title {
    font-weight: bold;
  }

  // f-ing bootstrap styles are taking over here
  .social-ddg-text--html blockquote {
    border-left: 5px solid lightgrey;
    padding-left: .5em;
    font-size: .90em;
    margin-bottom: .5em;
    padding-top: .5em;
  }
}
